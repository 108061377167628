import React from "react"

import Layout from "../components/layout"
import {graphql, Link} from "gatsby";
import Img from "gatsby-image"
import Blog from "../components/blog";
import SEO from "../components/seo";

const Header = ({title}) => <div className="flex justify-around items-center mt-12 mb-2">
  <hr className="border-2 m-0 border-yellow-700 w-1/3"/>
  <span className="text-center font-sans font-light text-black text-3xl">{title}</span>
  <hr className="border-2 m-0 border-yellow-700 w-1/3"/>
</div>

const BlogList = ({blogs}) => <div className="flex flex-wrap mx-4">
  {blogs.map(b => <Blog blog={b} key={b.slug}/>)}
</div>

export default ({data: {datoCmsBlogspage: p, allDatoCmsBlog: latestBlogs}}) => {
  const blogs = latestBlogs.edges.map(b => b.node)
  const eventBlogs = blogs.filter(b => b.category === 'event').slice(0, 3);
  const eventIds = eventBlogs.map(b => b.id)
  const otherBlogs = blogs.filter(b => !eventIds.includes(b.id))
  return (
    <Layout>
      <SEO title='Blogs' seo={p.seoMetaTags}/>
      <div className='flex flex-col'>
        <Header title='Upcoming fashion events'/>
        <BlogList blogs={eventBlogs}/>
        <Img fluid={p.image.fluid} alt='LookStyler fashion blog'/>
        <Header title={p.topStories}/>
        <BlogList blogs={otherBlogs.slice(0, 6)}/>
        <Header title={p.featured}/>
        <Link to={'/blogs/' + p.featuredBlog.slug + '/'} className='relative my-2'>
          <Img className='' fluid={p.featuredBlog.image.fluid}/>
          <div className="absolute left-0 top-0 bg-white flex flex-col my-6 p-6">
            <span className='font-sans font-light text-black py-2 text-4xl'>{p.featuredBlog.title}</span>
            <span className='font-sans text-red-700 '>{p.featuredBlog.category}</span>
          </div>
        </Link>
        <Header title={p.stylingTips}/>
        <BlogList blogs={p.stylingTipsBlogs}/>
        <Header title='Older stories'/>
        <BlogList blogs={otherBlogs.slice(6)}/>
      </div>
    </Layout>
  );
}

export const query = graphql`
    query Blogs {
        datoCmsBlogspage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            image {
                fluid(maxWidth: 1600, imgixParams: {fit: "crop", crop:"top", w: "1600", h: "800" }) {
                    ...GatsbyDatoCmsFluid
                }
            }
            topStories
            featured
            stylingTips
            featuredBlog {
                title
                slug
                image {
                    fluid(maxWidth: 2000, imgixParams: { fit: "crop", h: "700", w: "2000" }) {
                        ...GatsbyDatoCmsFluid
                    }
                }
                category
            }
            stylingTipsBlogs {
                slug
                title
                image {
                    fluid(maxWidth: 300, imgixParams: { fit: "crop", h: "300", w: "300" }) {
                        ...GatsbyDatoCmsFluid
                    }
                }
                category
            }
        }
        allDatoCmsBlog(sort: {fields: [meta___createdAt], order: DESC}){
            edges{
                node{
                    id
                    title
                    slug
                    category
                    image {
                        fluid(maxWidth: 300, imgixParams: { fit: "crop", h: "300", w: "300" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }

    }
`
