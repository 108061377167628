import {Link} from "gatsby";
import Img from "gatsby-image"
import React from "react";

const Blog = ({blog: b}) => <Link to={`/blogs/${b.slug}/`} style={{minWidth: '200px'}}
                                  className="flex-1 flex flex-col p-4 no-underline">
  <Img fluid={b.image.fluid} alt={b.title}/>
  <span className='font-sans font-light text-black py-2'>{b.title}</span>
  <span className='font-sans text-red-600 '>{b.category}</span>
</Link>

export default Blog